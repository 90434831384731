export const PAGES_INFO = {
  landingPage: {
    path: '/',
    filename: './src/page-files/index.tsx',
    translationKey: 'nav.link.landingPage'
  },
  suomiAreenaPoriPage: {
    path: '/pori',
    filename:'./src/page-files/pori.tsx',
    translationKey: 'nav.link.suomiAreenaPoriPage'
  },
  suomiAreenaPoriInfoPage: {
    path: '/pori/tietoatapahtumasta',
    filename:'./src/page-files/pori-info.tsx',
    translationKey: 'nav.link.suomiAreenaPori.info'
  },
  // suomiAreenaLavatInfoPage: {
  //   path: '/pori/lavat',
  //   filename:'./src/page-files/pori-lavat.tsx',
  //   translationKey: 'nav.link.suomiAreenaPori.stages'
  // },
  // suomiAreenaStipendiPage: {
  //   path: '/pori/hinnatjastipendit',
  //   filename:'./src/page-files/pori-hinnat-ja-stipendit.tsx',
  //   translationKey: 'nav.link.suomiAreenaPori.prices'
  // },
  suomiAreenaPoriOhjelmaPage: {
    path: '/pori/ohjelma',
    matchPath: '/pori/ohjelma/*',
    filename:'./src/page-files/pori-event.tsx',
    translationKey: 'nav.link.suomiAreenaPori.program'
  },
  suomiAreenaPoriBecomePartnerPage: {
    path: '/pori/yhteistyokumppanit',
    filename:'./src/page-files/pori-partners.tsx',
    translationKey: 'nav.link.suomiAreenaPori.Partners'
  },
  suomiAreenaPoriJoinToCrewPage: {
    path: '/pori/haemukaan',
    filename:'./src/page-files/pori-join-crew.tsx',
    translationKey: 'nav.link.suomiAreenaPori.joinCrew'
  },
  // suomiAreenaPoriLisatietoStipendeistaPage: {
  //   path: '/pori/lisatietoa-stipendeista',
  //   filename:'./src/page-files/pori-lisatietoa-stipendeista.tsx',
  //   translationKey: 'nav.link.suomiAreenaPori.lisatietoStipendeista'
  // },
  // suomiAreenaPoriKansalaistoripaikatPage: {
  //   path: '/pori/kansalaistoripaikat',
  //   filename:'./src/page-files/pori-kansalaistoripaikat.tsx',
  //   translationKey: 'nav.link.suomiAreenaPori.kansalaistoripaikat'
  // },
  suomiAreenaPoriVastuullisuusPage: {
    path: '/pori/vastuullisuus',
    filename:'./src/page-files/pori-vastuullisuus.tsx',
    translationKey: 'nav.link.suomiAreenaPori.vastuullisuus'
  },
  /* suomiAreenaGoesTietoaPage: {
    path: '/suomiareenagoes',
    filename:'./src/page-files/goes.tsx',
    translationKey: 'nav.link.suomiAreenaGoesTietoaPage'
  },
  suomiAreenaGoesPage: {
    path: '/suomiareenagoes',
    filename:'./src/page-files/goes.tsx',
    translationKey: 'nav.link.suomiAreenaGoesPage'
  },
  suomiAreenaGoesOhjelmaPage: {
    path: '/suomiareenagoes/ohjelma',
    filename:'./src/page-files/goes-event.tsx',
    translationKey: 'nav.link.suomiAreenaGoes.program'
  }, 
  suomiAreenaGoesMediaPage: {
    path: '/suomiareenagoes-media',
    filename:'./src/page-files/goes-media.tsx',
    translationKey: 'nav.link.suomiAreenaGoes.media'
  },
  suomiAreenaGoesBecomePartnerPage: {
    path: '/suomiareenagoes/yhteistyokumppaniksi',
    filename:'./src/page-files/goes-become-partner.tsx',
    translationKey: 'nav.link.suomiAreenaGoes.becomePartner'
  },*/
  brieflyInEnglishPage: {
    path: '/en',
    filename:'./src/page-files/briefly-in-english.tsx',
    translationKey: 'nav.link.brieflyInEnglishPage'
  },
  kortPaSvenskaPage: {
    path: '/swe',
    filename:'./src/page-files/kort-pa-svenska.tsx',
    translationKey: 'nav.link.kortPaSvenskaPage'
  },
  contact: {
    path: '/yhteystiedot',
    filename: './src/page-files/contact.tsx',
    translationKey: 'nav.link.contact'
  },
};

export const TEMPLATES_INFO = {
  campaign: {
    path: '/kampanjat/',
    filename: './src/templates/campaign.tsx',
  },
  article: {
    path: '/artikkelit/',
    filename: './src/templates/article.tsx',
  },
  mediaInfoPage: {
    path: '/mediatiedot/',
    filename: './src/templates/media-info-page.tsx',
  },
  reference: {
    path: '/referenssit/',
    filename: './src/templates/reference.tsx',
  },
  mediaInfoTotalTv: {
    path: '/mediatiedot/total-tv-mainonta/',
    filename: './src/templates/media-info.tsx',
  },
  mediaInfoOnlineAdvertising: {
    path: '/mediatiedot/verkkomainonta/',
    filename: './src/templates/media-info.tsx',
  },
  material: {
    path: '/mediatiedot/aineistot/',
    filename: './src/templates/media-info.tsx',
  },
  salesConditions: {
    path: '/mediatiedot/myyntiehdot/',
    filename: './src/templates/media-info.tsx',
  },
  brieflyInEnglish: {
    path: '/en/',
    filename: './src/templates/briefly-in-english.tsx',
  },
};

export const MEDIA_INFO = {
  totalTv: {
    path: '/mediatiedot/total-tv',
  },
  onlineAdvertising: {
    path: '/mediatiedot/verkkomainonta',
  },
  material: {
    path: '/mediatiedot/aineistot/',
  },
  salesConditions: {
    path: '/mediatiedot/myyntiehdot/',
  },
};
